import ApiService from '@/api/new/services/api.service'
import i18n from '@/i18n'

const state = {
  workflows: [],
  phraseWorkflows: [],
  pages: {
    current: 0,
    total: 0
  }
}

const mutations = {
  setWorkflows(state, workflows) {
    state.workflows = workflows.filter((flow) => !flow.attributes.is_predefined)
  },
  setPhraseWorkflows(state, phraseWorkflows) {
    state.phraseWorkflows = phraseWorkflows.filter(
      (flow) => !flow.attributes.is_predefined
    )
  },
  setPages(state, page) {
    state.pages.current = page.current_page
    state.pages.total = page.last_page
  }
}

const actions = {
  async getStateWorkflows({ dispatch, state }) {
    if (state.workflows.length === 0)
      await dispatch('getWorkflows', { page: 1 })
  },
  async getWorkflows({ commit, rootGetters }, { page }) {
    const params = new URLSearchParams()
    params.append('page', page)

    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/workflows`,
      { params }
    )
      .then(async (res) => {
        const workflows = res.data.data
        const pages = res.data.meta
        await commit('setWorkflows', workflows)
        await commit('setPages', pages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getPhraseWorkflows({ commit, rootGetters }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/workflows?filters[connector]=phrase`
    )
      .then(async (res) => {
        const workflows = res.data.data
        await commit('setPhraseWorkflows', workflows)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async createNewFlow({ dispatch, rootGetters }, { name, description }) {
    const data = {
      data: {
        type: 'account_workflows',
        attributes: {
          display_name: name,
          description
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/workflows`,
      data
    )
      .then(async () => {
        await dispatch('getWorkflows', { page: 1 })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteWorkflow({ dispatch, state, rootGetters }, { id }) {
    await ApiService.delete(
      `teams/${rootGetters['workspace/currentAccountId']}/workflows/${id}`
    )
      .then(async () => {
        await dispatch(
          'toast/success',
          { message: i18n.t('customer.toast.success.flow_deleted') },
          { root: true }
        )
        await dispatch('getWorkflows', { page: state.pages.current })
        await dispatch('getPhraseWorkflows')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

export const automationFlows = {
  namespaced: true,
  state,
  mutations,
  actions
}
