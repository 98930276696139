import ApiEasycatService from '@/api/new/services/api.easycat.service'
import ApiService from '@/api/new/services/api.service'
import i18n from '@/i18n'
import FileSaver from 'file-saver'

const state = {
  showBetaEditor: true,
  showEditorPreview: true,
  currentSegment: null,
  currentSegmentId: null,
  currentSegmentRowId: null,
  translationHistory: [],
  filterInput: null,
  uploadedFile: null,
  segmentStatistics: null,
  failedSegments: null,
  segmentTagMismatch: false,
  repetition: {},
  linkedSegment: null,
  fileErrorCodes: {},
  templates: [],
  failedPages: {
    current: 1,
    total: 0,
    totalSegments: 0,
    pageSize: 10
  }
}

const mutations = {
  setCurrentSegment(state, segment) {
    state.currentSegment = segment || null
    state.currentSegmentId = segment.id || null
  },
  setCurrentSegmentRowId(state, id) {
    state.currentSegmentRowId = id
  },
  setTranslationHistory(state, segment) {
    state.translationHistory = segment
  },
  setFilterInput(state, filter) {
    state.filterInput = filter
  },
  setShowBetaEditor(state, show) {
    state.showBetaEditor = show
  },
  setShowEditorPreview(state, show) {
    state.showEditorPreview = show
  },
  setUploadedFile(state, file) {
    state.uploadedFile = file
    state.segmentStatistics = file.attributes.segment_statistics
    state.fileErrorCodes = file.attributes.error_codes
  },
  setFailedSegments(state, segments) {
    state.failedSegments = segments
  },
  setRevisionFailedSegment(state, { index, segment }) {
    state.failedSegments[index] = segment
  },
  setFailedSegmentTarget(state, { index, target, confirmed }) {
    state.failedSegments[index].attributes.target = target
    state.failedSegments[index].attributes.is_confirmed = confirmed
  },
  setFailedPages(state, pages) {
    state.failedPages.current = pages.current_page
    state.failedPages.total = pages.last_page
    state.failedPages.totalSegments = pages.total
    state.failedPages.pageSize = pages.per_page
  },
  setSegmentTagMismatch(state, isMismatch) {
    state.segmentTagMismatch = isMismatch
  },
  setRepetition(state, repetition) {
    state.repetition = repetition
  },
  setCurrentLinkedSegment(state, segment) {
    state.linkedSegment = segment
  },
  setTemplates(state, templates) {
    state.templates = templates
  }
}

const actions = {
  async getTranslationFile(
    { state, commit, getters, dispatch, rootGetters },
    { target, currentPage, perPage, failedPage }
  ) {
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/files/${getters.fileId}/file-segments`,
      {
        params: {
          page: currentPage,
          perPage: perPage || 10,
          target_language: target,
          task_type: getters.taskType
        }
      }
    )
      .then(async (res) => {
        const file = res.data.data
        const pages = res.data.meta
        commit('task/setRevisionFile', file, { root: true })
        commit('task/setFilePages', pages, { root: true })
        await commit('setTranslationHistory', {})
        await dispatch('getUploadedFile')
        if (rootGetters['task/isAiTask']) {
          if (state.showBetaEditor)
            await dispatch('getFailedSegments', {
              target,
              currentPage: failedPage || state.failedPages.current,
              perPage: 10
            })
        }
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getFailedSegments(
    { commit, getters, rootGetters, rootState },
    { target, currentPage, perPage }
  ) {
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/files/${getters.fileId}/file-segments/failed`,
      {
        params: {
          page: currentPage,
          perPage,
          target_language: target,
          task_type: getters.taskType
        }
      }
    )
      .then(async (res) => {
        const segments = res.data.data
        const pages = res.data.meta
        commit('setFailedSegments', segments)
        commit('setFailedPages', pages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getUploadedFile({ commit, getters, rootGetters }) {
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/files/${getters.fileId}`
    )
      .then(async (res) => {
        const file = res.data.data
        commit('setUploadedFile', file)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateAllSegmentsStatus(
    { dispatch, getters, rootGetters, rootState },
    payload
  ) {
    const perPage = rootState['task'].filePages.pageSize
    const data = {
      data: {
        type: 'file_segments',
        attributes: {
          is_confirmed: payload.status,
          target_language: payload.target,
          task_type: getters.taskType
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/files/${getters.fileId}/file-segments/bulk-confirm`,
      data
    )
      .then(async () => {
        dispatch(
          'toast/success',
          {
            message: payload.status
              ? i18n.t('customer.toast.success.confirm_segments')
              : i18n.t('shared.toast.success.uncofirm_segments')
          },
          { root: true }
        )
        await dispatch('getTranslationFile', {
          target: payload.target,
          currentPage: rootState['task'].filePages.current,
          perPage
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateSegment(
    { state, dispatch, commit, getters, rootGetters, rootState },
    payload
  ) {
    await commit('task/setIsReviewSaved', false, { root: true })
    if (!payload.notUpdatingSegment)
      await commit('task/setSavingSegment', true, { root: true })
    const target = rootState['task'].currentTask.attributes.target_language
    const currentPage = rootState['task'].filePages.current
    const perPage = rootState['task'].filePages.pageSize
    const data = {
      data: {
        type: 'file_segments',
        attributes: {
          ...payload.attributes,
          task_type: getters.taskType
        }
      }
    }
    await ApiEasycatService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/files/${getters.fileId}/file-segments/${payload.id}`,
      data
    )
      .then(async (response) => {
        if (!payload.propagate) {
          // force get file, to update propagations of other segments
          if (payload.getFile) {
            if (state.filterInput) {
              await dispatch('filterAiFileSegments', {
                ...state.filterInput,
                currentPage
              })
              await commit('task/setSavingSegment', false, { root: true })
            } else {
              await dispatch('getTranslationFile', {
                target,
                currentPage,
                perPage,
                failedPage: payload.nextPage
              })
              await commit('task/setSavingSegment', false, { root: true })
            }
          }
          // update only segment and linked ones
          else {
            dispatch('applySegmentUpdate', response)
          }

          if (rootGetters['task/isAiTask'] && payload.nextPage !== undefined) {
            if (state.showBetaEditor)
              await dispatch('getFailedSegments', {
                target,
                currentPage: payload.nextPage || state.failedPages.current,
                perPage: 10
              })
          }
          await dispatch('getFileSegmentHistory', payload.id)
          await commit('task/setIsReviewSaved', true, { root: true })
          await commit('task/setSavingSegment', false, { root: true })
        }
      })
      .catch((err) => {
        commit('task/setSavingSegment', false, { root: true })
        throw new Error(JSON.stringify(err))
      })
  },
  async updateRepetitions(
    { dispatch, getters, rootGetters, rootState },
    payload
  ) {
    const target = rootState['task'].currentTask.attributes.target_language
    const data = {
      data: {
        type: 'file_segments',
        attributes: {
          ...payload.attributes
        }
      }
    }
    await ApiEasycatService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/files/${getters.fileId}/file-segments/repetitions-propagate`,
      data
    )
      .then(async () => {
        await dispatch('getTranslationFile', {
          target: target,
          currentPage: rootState['task'].filePages.current,
          perPage: rootState['task'].filePages.pageSize
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async filterAiFileSegments(
    { dispatch, commit, getters, rootState, rootGetters },
    payload
  ) {
    const perPage = rootState['task'].filePages.pageSize
    const { all, currentPage, ...attributes } = payload
    const data = {
      data: {
        type: 'files',
        attributes: {
          ...attributes,
          task_type: getters.taskType
        }
      }
    }
    if (!payload.value && payload.all) {
      await commit('setFilterInput', null)
      return await dispatch('getTranslationFile', {
        target: payload.target_language,
        currentPage: rootState['task'].filePages.current,
        perPage
      })
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/files/${getters.fileId}/search?perPage=${perPage}&page=${payload.currentPage || 1}`,
      data
    )
      .then(async (res) => {
        const file = res.data.data
        const pages = res.data.meta
        commit('task/setRevisionFile', file, { root: true })
        commit('task/setFilePages', pages, { root: true })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async searchAndReplace(
    { dispatch, getters, rootState, rootGetters },
    payload
  ) {
    const target = rootState['task'].currentTask.attributes.target_language
    const source = rootState['task'].currentTask.attributes.source_language
    const data = {
      data: {
        type: 'files',
        attributes: {
          target_language: target,
          search_value: payload.search,
          replace_value: payload.replace,
          segment_ids: payload.segmentIds
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/files/${getters.fileId}/replace-all`,
      data
    )
      .then(async () => {
        await dispatch('filterAiFileSegments', {
          source_language: source,
          target_language: target,
          value: payload.search,
          type: 'target'
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getStateFileSegmentHistory({ state, dispatch }, id) {
    if (
      state.translationHistory.length === 0 ||
      state.translationHistory[0]?.attributes?.segment_id !== id
    )
      await dispatch('getFileSegmentHistory', id)
  },
  async getFileSegmentHistory({ commit, getters, rootGetters }, id) {
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/files/${getters.fileId}/file-segments/${id}/history`
    )
      .then(async (res) => {
        const history = res.data.data
        await commit('setTranslationHistory', history)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getFuzzySegments({ commit, getters, rootGetters }, id) {
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/files/${getters.fileId}/file-segments/${id}/fuzzy`
    )
      .then(async (res) => {
        const segments = res.data.data.attributes.fuzzy_matches.data
        const matchedFound = segments.length > 0 ? true : false
        const obj = {
          found: matchedFound,
          segments
        }
        commit('task/setMatchedSegments', obj, { root: true })
        commit(
          'task/setSearchPages',
          {
            current_page: 1,
            last_page: 0,
            total: 0,
            per_page: 50
          },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async finishTranslation({ getters, rootState, rootGetters }) {
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/projects/${rootState['project'].currentProject.id}/tasks/${rootState['task'].currentTask.id}/complete-translation`
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  },
  async filterTemplates({ commit, rootGetters }, payload) {
    const data = {
      data: {
        type: 'templates',
        attributes: payload
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/templates/filter`,
      data
    )
      .then(async (res) => {
        const templates = res.data.data
        commit('setTemplates', templates)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async downloadExportFile({ dispatch, rootGetters }, id) {
    const requestData = {
      method: 'post',
      url: `/laas/api/v1/teams/${rootGetters['workspace/currentAccountId']}/exports/download/${id}`,
      data: {},
      responseType: 'blob'
    }
    await ApiService.customRequest(requestData)
      .then((res) => {
        const contentType = res.headers['content-type']

        let fileExtension = ''
        if (contentType.includes('zip')) fileExtension = 'zip'
        else if (contentType.includes('xlsx')) fileExtension = 'xlsx'
        else {
          dispatch(
            'toast/error',
            {
              message: i18n.t('server.validation.mimes')
            },
            { root: true }
          )
          return
        }

        let fileSource = window.URL.createObjectURL(
          new Blob([res.data], { type: contentType })
        )
        FileSaver.saveAs(fileSource, `exported-assets.${fileExtension}`)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async filterTemplates({ commit, rootGetters }, payload) {
    const data = {
      data: {
        type: 'templates',
        attributes: payload
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/templates/filter`,
      data
    )
      .then(async (res) => {
        const templates = res.data.data
        commit('setTemplates', templates)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  applySegmentUpdate(
    { state, dispatch, commit, getters, rootGetters, rootState },
    payload
  ) {
    // check if updated segment has repetitions
    const hasRepetitions =
      payload.data.data.attributes.linked_repetitions?.length > 0 || false
    const shouldPropagate =
      payload.data.data.attributes?.repetition?.propagate || false

    if (state.showBetaEditor && rootGetters['task/isAiTask']) {
      // find the index of the updated segment in the failed segments array
      const index = state.failedSegments.findIndex(
        (seg) => seg.id === payload.data.data.id
      )
      // if found update the segment
      if (index !== -1) {
        commit('setRevisionFailedSegment', {
          index,
          segment: payload.data.data
        })
      }
    } else {
      // find the index of the updated segment in the file segments
      const index = rootState['task'].file.findIndex(
        (seg) => seg.id === payload.data.data.id
      )
      // if found update the segment
      if (index !== -1) {
        commit(
          'task/setRevisionFileSegment',
          { index, segment: payload.data.data },
          { root: true }
        )
      }
    }

    // if the updated segment has repetitions, update them as well
    if (hasRepetitions && shouldPropagate) {
      const segmentRepetitions = payload.data.data.attributes.linked_repetitions
      const isConfirmed = payload.data.data.attributes.is_confirmed

      // when beta editor, update failed segment
      if (state.showBetaEditor && rootGetters['task/isAiTask']) {
        segmentRepetitions.forEach((repetition) => {
          const failedIndex = state.failedSegments.findIndex(
            (failedSegment) => failedSegment.id === repetition['_id']
          )

          if (failedIndex !== -1) {
            // check if segment allows propagation
            const allowPropagation =
              state.failedSegments[failedIndex].attributes.repetition.propagate

            if (allowPropagation) {
              commit('setFailedSegmentTarget', {
                index: failedIndex,
                target: repetition.target,
                confirmed: isConfirmed
              })
            }
          }
        })
      }
      // otherwise update file segment
      else {
        segmentRepetitions.forEach((repetition) => {
          const segmentIndex = rootState['task'].file.findIndex(
            (fileSegment) => fileSegment.id === repetition['_id']
          )

          if (segmentIndex !== -1) {
            // check if segment allows propagation
            const allowPropagation =
              rootState['task'].file[segmentIndex].attributes.repetition
                .propagate

            if (allowPropagation) {
              commit(
                'task/setRevisionFileSegmentTarget',
                {
                  index: segmentIndex,
                  target: repetition.target,
                  confirmed: isConfirmed
                },
                { root: true }
              )
            }
          }
        })
      }
    }
  }
}
const getters = {
  fileId: (state, getters, rootState, rootGetters) => {
    return rootState['project'].currentProject.attributes.cattool_id
  },
  fileType: (state) => {
    return state.uploadedFile?.attributes?.type
  },
  allowTagsCheck: (state, getters, rootState, rootGetters) => {
    if (!rootGetters['task/isAiTask']) return true
    return getters.fileType !== 'sdlxliff'
  },
  isAiCompleted: (state, getters, rootState, rootGetters) => {
    return (
      rootState['task'].currentTask.attributes.status === 'COMPLETED' ||
      rootState['task'].currentTask.attributes.type === 'internal_review'
    )
  },
  taskType: (state, getters, rootState, rootGetters) => {
    return !rootGetters['task/isAiTask']
      ? rootState['task'].currentTask.attributes.type
      : rootState['task'].currentTask.attributes.status === 'COMPLETED'
        ? 'internal_review'
        : 'translation'
  },
  currentPageFailedSegments: (state, getters, rootState, rootGetters) => {
    const threshold = state.uploadedFile.attributes.pipeline.qa.threshold
    const failedSegments = rootState['task'].file.filter(
      (s) => s.attributes.qa && s.attributes.qa.average <= threshold
    )
    return failedSegments
  },
  getLinkedSegment: () => (s) => {
    if (!s.attributes.linked_repetitions) return []

    let linkedSegments = [...s.attributes.linked_repetitions]
    const segment = {
      order: s.attributes.order,
      repetition: s.attributes.repetition,
      source: s.attributes.source,
      target: s.attributes.target,
      _id: s.id
    }
    linkedSegments.push(segment)
    return [...linkedSegments].sort((a, b) => a.order - b.order)
  }
}

export const file = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
